$(".si-dropdown").each(function () {
  let popperFn = (reference, target, placement, offset) => {
    Popper.createPopper(reference, target, {
      placement: placement || "bottom",
      modifiers: [
        {
          name: "offset",
          options: {
            offset: offset || [0, 8],
          },
        },
      ],
    });
  };

  const button = $(this).find("[data-toggle]");
  const _reference = $(button).attr("data-toggle");
  const _target = $(`${$(button).attr("data-target")}`);

  const reference = document.querySelector(`[data-toggle='${_reference}']`);
  const dropdown = _target[0];
  const placement = $(this).attr("data-placement") || null;
  const offset = $(this).attr("data-offset");
  let _offset = offset ? JSON.parse(offset) : null;

  popperFn(reference, dropdown, placement, _offset);
});

$(document).on("click touchend", function (e) {
  const references = $("[data-toggle='si-dropdown']");
  const target = $(e.target);

  $(references).each(function (elem) {
    const reference = this;
    const _target = $(reference).attr("data-target");

    const popover = $(`${_target}`);

    if ($(target).is(popover) || popover.find(target).length !== 0) {
      return;
    }

    if ($(target).is(reference) || $(reference).find(target).length !== 0) {
      if (!popover.hasClass("si-dropdown__menu--show")) {
        popover.addClass("si-dropdown__menu--show");
        return;
      }
    }

    popover.removeClass("si-dropdown__menu--show");
  });
});
